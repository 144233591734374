<template>
  <common-evaluation :is-full-time="false" />
</template>

<script>
  import CommonEvaluation from './CommonEvaluation'

  export default {
    name: 'EvaluationParttime',
    components: {
      'common-evaluation': CommonEvaluation,
    },
    data () {
      return {}
    },
    computed: {},
    watch: {},
    created () {},
    mounted () {},
    methods: {},
  }
</script>
<style scoped lang="sass">
</style>
